<template>
	<div id="deviceMng" v-loading.fullscreen.lock="loading" element-loading-text="请求中，请稍后"
		element-loading-background="rgba(255, 255, 255, 0.7)">
		<div class="c-query">
			<el-button type="primary" @click="showAddEditDialog('add')">添加设备</el-button>
		</div>
		<div class="c-container-box">
			<el-table :data="dataArrFilter">
				<el-table-column prop="title" label="设备名称"></el-table-column>
				<el-table-column prop="id" label="设备UID"></el-table-column>
				<el-table-column label="数据项" show-overflow-tooltip>
					<template slot-scope="scope">
						{{parseDataItem(scope.row.model)}}
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<!-- <el-tooltip content="所属用户" placement="bottom">
							<el-button type="primary" icon="el-icon-document" circle size="mini"
								@click="showUserDialog"></el-button>
						</el-tooltip> -->
						<!-- <el-tooltip content="编辑" placement="bottom"> -->
						<el-button type="primary" icon="el-icon-edit" circle size="mini"
							@click="showAddEditDialog('edit',scope.row)">
						</el-button>
						<!-- </el-tooltip> -->
						<!-- <el-tooltip content="删除" placement="bottom"> -->
						<el-button type="danger" icon="el-icon-delete" circle size="mini"
							@click="showDeleteDialog('del',scope.row)">
						</el-button>
						<!-- </el-tooltip> -->
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background :page-sizes="[10, 15, 20, 25, 30]" :page-size="page.size"
				:current-page="page.curPage" layout="total, sizes, prev, pager, next" :total="dataArr.length"
				v-show="dataArr.length>0" @size-change="sizeChange" @current-change="pageChange">
			</el-pagination>
		</div>
		<!-- 所属用户 -->
		<Dialog :data="userDialog" @sure="onSure">
			<template>
				<div class="user-dialog">
					<el-tree :data="userTreeData" ref="userTree" show-checkbox node-key="id" default-expand-all>
					</el-tree>
				</div>
			</template>
		</Dialog>
		<!-- 添加/编辑设备 -->
		<Dialog :data="addEditDialog" @sure="onSure">
			<template>
				<div class="add-dialog">
					<div class="c-item">
						<span>设备UID：</span>
						<el-input :disabled="operateType=='add'?false:true" v-model="addEditDialog.data.uid">
						</el-input>
					</div>
					<div class="c-item">
						<span>设备名称：</span>
						<el-input v-model="addEditDialog.data.name"></el-input>
					</div>
					<div v-show="operateType=='add'">
						<p>所属组织：</p>
						<div class="org">
							<el-tree :data="orgTreeData" ref="orgTree" show-checkbox node-key="id" default-expand-all
								:expand-on-click-node="false" :filter-node-method="filterNode" check-on-click-node
								check-strictly @check="checkChange" :default-checked-keys="[addEditDialog.data.org]">
								<span class="custom-tree-node" slot-scope="{ node, data }">
									<i v-show="data.type!='org'" class="iconfont icon_device"></i>&nbsp;
									<span>{{ data.title }}</span>
								</span>
							</el-tree>
						</div>
					</div>
				</div>
			</template>
		</Dialog>
		<!-- 删除设备 -->
		<Dialog :data="deleteDialog" @sure="onSure">
			<template>
				<div class="delete-dialog">
					您确定删除设备 “{{deleteDialog.uid}}” 吗？
				</div>
			</template>
		</Dialog>
	</div>
</template>

<script>
	import Dialog from '../../components/Dialog.vue';

	export default {
		data() {
			return {
				userTreeData: [], //所属用户树列表
				treeData: [], //所属组织树列表
				// 所属用户dialog
				userDialog: {
					show: false,
					title: '所属用户',
					width: '500px'
				},
				// 添加设备/编辑dialog
				addEditDialog: {
					show: false,
					title: '添加设备',
					width: '500px',
					data: {
						uid: '', //设备UID——input
						name: '', //设备名称——input
						curData: {}, //当前数据
						orgData: {}, //所属组织
						org: '0' //所属组织
					}
				},
				// 删除dialog
				deleteDialog: {
					show: false,
					title: '删除设备',
					width: '400px',
					uid: '',
					parentId: ''
				},
				defaultProps: {
					children: 'children',
					label: 'title'
				},
				operateType: '', //操作类型：user所属用户，add添加，edit编辑，del删除
				loading: false,
				// page
				page: {
					size: 10,
					curPage: 1
				}
			}
		},
		components: {
			Dialog
		},
		computed: {
			dataArr() {
				return this.$store.getters.getCurDevices;
			},
			dataArrFilter() {
				let page = this.page;
				let arr = this.dataArr.filter((item, index) => index >= ((page.curPage - 1) * page.size) && index <= ((
					page.curPage * page.size) - 1));
				return arr;
			},
			orgTreeData() {
				return this.$store.state.treeData;
			},
			// 解析得到设备数据项
			parseDataItem() {
				return (model) => {
					let arr = this.$store.state.profile.profile.models[model];
					if (arr == undefined) {
						return "";
					}
					let dataTxt = "";
					for (let item in arr.fields) {
						dataTxt += arr.fields[item].title + "，"
					}
					return dataTxt.substring(0, dataTxt.length - 1);
				}
			}
		},
		watch: {
			dataArr(val) {
				if (this.operateType == 'del') {
					// 删除：如果当前页还有数据，则页码不改变
					if (val.length % this.page.size != 0) {
						return;
					}
					this.page.curPage = this.page.curPage > 1 ? --this.page.curPage : 1;
					this.operateType = '';
				} else {
					// 左侧选择区域，重置页码
					this.page.curPage = 1;
				}
			}
		},
		methods: {
			// 过滤节点，只显示type=='org'的节点
			filterNode(value, data) {
				if (!value) return true;
				return data.type == value;
			},
			// 组织树选中点击事件
			checkChange(data, node) {
				this.$refs.orgTree.setCheckedKeys([data.id]);
				this.addEditDialog.data.orgData = data;
				this.addEditDialog.data.org = data.id;
			},
			showUserDialog() {
				this.userDialog.show = true;
			},
			showAddEditDialog(type, data) {
				this.operateType = type;
				this.addEditDialog.show = true;
				setTimeout(() => {
					this.$refs.orgTree.filter("org");
				}, 1)
				// 匹配数据
				let setData = (title, uid, name, orgData, org) => {
					this.addEditDialog.title = title;
					this.addEditDialog.data = {
						uid: uid,
						name: name,
						orgData: orgData,
						org: org
					}
				}
				if (type == 'add') {
					setData("添加设备", '', '', this.addEditDialog.data.orgData, this.addEditDialog.data.org);
				} else {
					setData("编辑", data.id, data.title, this.addEditDialog.data.orgData, this.addEditDialog.data.org);
					this.addEditDialog.data.curData = data;
				}
			},
			showDeleteDialog(type, data) {
				this.operateType = type;
				this.deleteDialog.show = true;
				this.deleteDialog.uid = data.id;
				this.deleteDialog.parentId = data.parentId;
			},
			// 操作设备——所属用户/添加/编辑/删除
			onSure() {
				switch (this.operateType) {
					case "user":
						this.users();
						break;
					case "add":
						this.addDevice();
						break;
					case "edit":
						this.editDevice();
						break;
					case "del":
						this.deleteDevice();
						break;
					default:
						break;
				}
			},
			users() {

			},
			addDevice() {
				// 添加设备请求
				let data = this.addEditDialog.data;
				if (data.org == '0') {
					data.orgData = this.$refs.orgTree.getCheckedNodes()[0];
				}
				if (this.fieldCheck(data)) {
					if (!data.orgData && data.orgData.type != "org") {
						this.common.cToast(this, '组织选择错误');
						return;
					}
					let json = {
						"cmd": "addDevice",
						"uid": data.uid,
						"title": data.name ? data.name : '',
						"regionId": data.org
					}
					this.ws.send(json);
					this.loading = true;
				}
			},
			editDevice() {
				// 编辑设备请求
				let data = this.addEditDialog.data;
				// if (this.fieldCheck(data)) {
				let json = {
					"cmd": "editDevice",
					"uid": data.uid,
					"title": data.name ? data.name : ''
				}
				this.ws.send(json);
				this.loading = true;
				// }
			},
			deleteDevice() {
				// 删除设备请求
				let json = {
					"cmd": "removeDevice",
					"uid": this.deleteDialog.uid
				}
				this.ws.send(json);
				this.loading = true;
			},
			// 字段检查
			fieldCheck(data) {
				if (data.uid === "") {
					this.common.cToast(this, '请输入设备UID');
					return false;
				}
				// if (data.name == "") {
				// 	this.common.cToast(this, '请输入设备名称');
				// 	return false;
				// }
				if (data.org === "") {
					this.common.cToast(this, '请选择所属组织');
					return false;
				}
				return true;
			},
			// 分页——当前页改变
			sizeChange(size) {
				this.page.size = size;
				this.page.curPage = 1;
			},
			pageChange(curPage) {
				this.page.curPage = curPage;
			}
		},
		mounted() {
			// this.dataArr = this.common.cGetDeviceData();
			// this.treeData = this.common.cGetTreeData();

			// 设备数据
			// let deviceData = this.$store.getters.getCurDevices;
			// this.dataArr = deviceData;

			// 操作成功/失败
			const operateResult = (type, dialog) => {
				this.loading = false;
				if (type == "ok") {
					this.common.cResultOk(this);
				} else {
					this.common.cResultNo(this);
				}
				dialog.show = false;
			}

			// 添加设备result
			this.ws.addCallback("addDeviceAck", (res) => {
				if (!this.common.cGetResult(res)) {
					operateResult('no', this.addEditDialog);
				}
			})
			this.ws.addCallback("eventDeviceAdded", (res) => {
				let data = this.addEditDialog.data.orgData;
				let r = res.devices[0];
				const newChild = {
					id: r.uid,
					uid: r.uid,
					title: r.title ? r.title : '',
					model: r.model,
					parentId: r.regionId,
					type: 'device',
					// 全部初始化，不然不更新
					online: '0',
					datas: [],
					videoConfig: {},
					gpsConfig: {}
				};
				if (!data.children) {
					this.$set(data, 'children', []);
				}
				data.children.push(newChild);
				operateResult('ok', this.addEditDialog);
			})

			// 编辑设备result
			this.ws.addCallback("editDeviceAck", (res) => {
				if (!this.common.cGetResult(res)) {
					operateResult('no', this.addEditDialog);
				}
			})
			this.ws.addCallback("eventDeviceEdited", (res) => {
				let data = this.addEditDialog.data.curData;
				data.title = res.title ? res.title : '';
				operateResult('ok', this.addEditDialog);
			})
			this.ws.addCallback("moveToRegionAck", (res) => {

			})
			this.ws.addCallback("deviceMoved", (res) => {

			})

			// 删除设备result
			this.ws.addCallback("eventDeviceRemoved", (res) => {
				let item = {};
				const findNode = (nodes, id) => {
					for (let i = 0; i < nodes.length; i++) {
						if (nodes[i].id == id) {
							item = nodes[i];
						}
						if (nodes[i].children) {
							findNode(nodes[i].children, id);
						}
					}
					return item;
				}
				let node = findNode(this.orgTreeData, this.deleteDialog.parentId);
				if (node) {
					const children = node.children || node;
					const index = children.findIndex(d => d.id === this.deleteDialog.uid);
					children.splice(index, 1);
				}
				operateResult('ok', this.deleteDialog);
			})

			// 以下是所属用户模拟数据
			let users = new Array();
			for (let i = 0; i < 20; i++) {
				users.push({
					id: "user" + i,
					label: 'test_account' + i
				})
			}
			let userArr = [{
				id: 1,
				label: '所有用户',
				children: users
			}];
			this.userTreeData = userArr;
		}
	}
</script>

<style lang="scss">
	#deviceMng {

		.c-container-box {
			margin-top: 0;
		}

		.user-dialog {
			height: 400px;
			overflow: auto;
		}

		.add-dialog {
			padding: 0 10% 5% 10%;

			// 所属组织
			.org {
				height: 240px;
				padding: 10px;
				border: 1px solid darken($color: $border_color, $amount: 5%);
				border-radius: 4px;
				overflow: auto;
			}
		}

		.delete-dialog {
			padding: 4% 10%;
		}

		// 重写dialog样式
		.el-dialog__body {
			padding: 1% 5%;
		}
	}
</style>
